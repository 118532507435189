"use strict";
import "./../../sass/main.scss";
import onPageLoad from "./modules/onPageLoad";
import browserCheck from "./modules/detectBrowser";

// const preloader = document.querySelector('#preloader');
// if(preloader.offsetParent === null){
//     preloader.style.display = 'none';
//     preloader.style.opacity = 1;
// }
onPageLoad(function(){
    if(!browserCheck.isSupported())
    {
        const alertDiv = document.getElementById('browserAlert');
        if(!alert){
            console.error('Unable to render browser alert.');
            return;
        }
        alertDiv.style.display = 'block';
    }
});
