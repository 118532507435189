function onPageLoad(...props)
{
    const [callable = () => {}, delay = 50] = props;
    if(delay > 0){
        setTimeout(() => handlePageLoad(callable), delay);
        return;
    }
    handlePageLoad(callable);
}

function handlePageLoad(callable){
    const preloader = document.querySelector('#preloader');
    if(preloader && preloader.style.display !== "none"){
        preloader.style.display = 'none';
        preloader.style.opacity = 1;
    }
    callable();
}


export default onPageLoad;
