import hash from "../hash";

class DetectBrowser {
  constructor() {
    this.browser = { name: undefined, version: undefined };
    this.minimumVersions = {
      Chrome: 90,
      Firefox: 88,
      Edge: 90,
      Opera: 74,
      Safari: 14
    };
    this._detectBrowser();
  }

  _detectBrowser() {
    this.browser = (function (){
      let ua = navigator.userAgent,
          tem,
          M =
              ua.match(
                  /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
              ) || [];

      if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return { name: "IE", version: tem[1] || "" };
      }

      if (M[1] === "Chrome") {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (tem != null) {
          return { name: tem[1].replace("OPR", "Opera"), version: tem[2] };
        }
      }

      M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];

      if ((tem = ua.match(/version\/(\d+)/i)) != null) {
        M.splice(1, 1, tem[1]);
      }

      return { name: M[0], version: M[1] };
    })();
  }

  isSupported() {
    if(this.minimumVersions.hasOwnProperty(this.browser.name)) {
      if(+this.browser.version >= this.minimumVersions[this.browser.name]) {
        return true;
      }
    }
    return false;
  }

  getFingerprint() {
    let canvas = document.createElement('canvas');
    let gl = canvas.getContext('webgl');
    let graphics_card = gl.getParameter(gl.RENDERER);

    let factors = [
      window.screen.availWidth,
      window.screen.availHeight,
      window.screen.colorDepth,
      navigator.userAgent,
      navigator.platform,
      navigator.language,
      navigator.hardwareConcurrency,
      navigator.cookieEnabled,
      graphics_card
    ];


    return hash(factors.join('_'));
  }
}


export default new DetectBrowser();
